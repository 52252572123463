
import styles from './Card.module.scss'
import {AnchorHTMLAttributes} from "react";

interface CardProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  children?: any,
  className?: string,
}

export default function Card({children, className, ...props}: CardProps){
  return <a className={`${styles.card} ${className ? className : ''}`} {...props}>{children}</a>
}
